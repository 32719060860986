<template>
  <div>
    <!----------------------------------------- 主列表 --------------------------------------->
    <!---------------页眉相关 ----------------->
    <Row style="margin-top: 10px">
      <Col span="16" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务趋势/月</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunjgyfwdxbd"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.jgyfwdxbdColumns"
                  :data="dataMain.jgyfwdxbdData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="AgeLoading"
          >
            <template slot="value1"  slot-scope="props">
              <Input v-model="props.row.value1"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value6"  slot-scope="props">
              <Input v-model="props.row.value6"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value7"  slot-scope="props">
              <Input v-model="props.row.value7"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value8"  slot-scope="props">
              <Input v-model="props.row.value8"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value9"  slot-scope="props">
              <Input v-model="props.row.value9"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value10"  slot-scope="props">
              <Input v-model="props.row.value10"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value11"  slot-scope="props">
              <Input v-model="props.row.value11"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
            <template slot="value12"  slot-scope="props">
              <Input v-model="props.row.value12"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/月',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="8" style="padding: 0 5px">
        <Card>
          <div style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        ">
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>城市规划养老床位缺口占比</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwryJg"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.rjzlColumns"
                  :data="dataMain.rjzlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="rjzlTableLoading"
          >
            <template slot="value"  slot-scope="props">
              <Input v-model="props.row.value"
                     :active-change="false"
                     @on-blur="oldManSave('城市规划养老床位缺口占比',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <Row style="margin-top: 10px">
      <Col span="24" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>区县老人、区县失能老人、区县居家服务人次、区县服务投诉排名</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunGd"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>
          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :data="dataMain.fwxmsfData"
                  :columns="dataMain.fwxmsfColumns"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="FwxmsftableLoading"
          >
            <template slot="value1"  slot-scope="props">
              <Input v-model="props.row.value1"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value6"  slot-scope="props">
              <Input v-model="props.row.value6"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value7"  slot-scope="props">
              <Input v-model="props.row.value7"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value8"  slot-scope="props">
              <Input v-model="props.row.value8"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value9"  slot-scope="props">
              <Input v-model="props.row.value9"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
            <template slot="value10"  slot-scope="props">
              <Input v-model="props.row.value10"
                     :active-change="false"
                     @on-blur="oldManSave('排名',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
<!--      <Col span="8" style="padding: 0 5px">-->
<!--        <Card>-->
<!--          <div-->
<!--                  style="-->
<!--          display: flex;-->
<!--          justify-content: space-between;-->
<!--          width: 100%;-->
<!--          margin-bottom: 10px;-->
<!--        "-->
<!--          >-->
<!--            <div style="display: flex">-->
<!--              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">-->
<!--                <span>服务趋势/年</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <Button-->
<!--                      type="primary"-->
<!--                      @click="getFunjgyfwdxbd"-->
<!--                      icon="md-search"-->
<!--                      style="margin-right: 5px"-->
<!--              >查询</Button-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;页眉相关 END-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--          &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;表格相关 -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--          <Table-->
<!--                  ref="orderManAgeTable"-->
<!--                  border-->
<!--                  :columns="dataMain.jgyfwdxbdColumns"-->
<!--                  :data="dataMain.jgyfwdxbdData"-->
<!--                  size="small"-->
<!--                  :height="tableHeight"-->
<!--                  :width="tableWidth"-->
<!--                  highlight-row-->
<!--                  tooltip-theme="light"-->
<!--                  :loading="AgeLoading"-->
<!--          >-->
<!--            <template slot="value1"  slot-scope="props">-->
<!--              <Input v-model="props.row.value1"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value2"  slot-scope="props">-->
<!--              <Input v-model="props.row.value2"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value3"  slot-scope="props">-->
<!--              <Input v-model="props.row.value3"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value4"  slot-scope="props">-->
<!--              <Input v-model="props.row.value4"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value5"  slot-scope="props">-->
<!--              <Input v-model="props.row.value5"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value6"  slot-scope="props">-->
<!--              <Input v-model="props.row.value6"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value7"  slot-scope="props">-->
<!--              <Input v-model="props.row.value7"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--            <template slot="value8"  slot-scope="props">-->
<!--              <Input v-model="props.row.value8"-->
<!--                     :active-change="false"-->
<!--                     @on-blur="oldManSave('服务趋势/年',props)"-->
<!--              />-->
<!--            </template>-->
<!--          </Table>-->
<!--          &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;表格相关 END-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--        </Card>-->

<!--      </Col>-->
    </Row>
    <!----------------------------------------- 主列表 END--------------------------------------->
  </div>
</template>
<!--大屏数据维护-->
<script>
  import {
    getOneDtList,
    getThreeLeftList,
    updateTwoRight,
    updateoneYlXx,
    updateoneHlXx,
    updateOneYlZb,
    getOneRightOneListPage,
    updateoneHlZb,
    updateoneSqXx,
    updateoneSqZb,
    deleteOneRightOne,
    insertOneRightOne,
    getThreeRightList,
    getTwoTopList,
    updateThreeLeft,
    updateThreeRight,
    updateTwoTop,
    getTwoRightList,
  } from "./dataMaintenance";

//todo
export default {
  name: "dataMaintenance",
  data() {
    return {
      //--------------------------主页面表格用--------------------------
      tableHeight: 0,
      tableWidth: "",
      validation_data: {
        name: [
          { required: true, message: "政府信息不能为空", trigger: "blur" },
        ],
      },
      dataMain: {
        jgyfwdxbdData:[],
        orderManNlData:[],
        rjzlData:[],
        fwryRsData:[],//服务人员结构信息人社
        fwJgRsData:[],//服务人员结构信息机构
        qxfwjgData:[],//区县服务结构信息
        fwxmsfData:[],//服务项目收费信息
        jgyfwdxbdColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "趋势类型", key: "name", minWidth: 70, align: "center" },
          {title: "一月人次", slot: 'value1', align: "center", minWidth: 70,},
          {title: "二人人次", slot: 'value2', align: "center", minWidth: 70,},
          {title: "三月人次", slot: 'value3', align: "center", minWidth: 70,},
          {title: "四月人次", slot: 'value4', align: "center", minWidth: 70,},
          {title: "五月人次", slot: 'value5', align: "center", minWidth: 70,},
          {title: "六月人次", slot: 'value6', align: "center", minWidth: 70,},
          {title: "七月人次", slot: 'value7', align: "center", minWidth: 70,},
          {title: "八月人次", slot: 'value8', align: "center", minWidth: 70,},
          {title: "九月人次", slot: 'value9', align: "center", minWidth: 70,},
          {title: "十月人次", slot: 'value10', align: "center", minWidth: 70,},
          {title: "十一月人次", slot: 'value11', align: "center", minWidth: 70,},
          {title: "十二月人次", slot: 'value12', align: "center", minWidth: 70,},
        ],
        rjzlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "缺口类型", key: "type", minWidth: 120, align: "center" },
          {title: "存量满足/缺口", key: 'name', align: "center", minWidth: 70,},
          {title: "存量满足/缺口数量", slot: 'value', align: "center", minWidth: 70,},
        ],
        fwxmsfColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "排名类型", key: "name", minWidth: 90, align: "center" },
          { title: "汉滨区", slot: "value1", minWidth: 80, align: "center" },
          {title: "汉阴县", slot: 'value2', align: "center", minWidth: 80,},
          {title: "石泉县", slot: 'value3', align: "center", minWidth: 80,},
          {title: "宁陕县", slot: 'value4', align: "center", minWidth: 80,},
          {title: "紫阳县", slot: 'value5', align: "center", minWidth: 80,},
          {title: "岚皋县", slot: 'value6', align: "center", minWidth: 80,},
          {title: "平利县", slot: 'value7', align: "center", minWidth: 80,},
          {title: "镇坪县", slot: 'value8', align: "center", minWidth: 80,},
          {title: "旬阳市", slot: 'value9', align: "center", minWidth: 80,},
          {title: "白河县", slot: 'value10', align: "center", minWidth: 80,},
        ],

      },
      //--------------------------主页面表格用 END--------------------------
      AgeLoading: false,
      tableJgLoading: false,
      RstableLoading: false,
      rjzlTableLoading: false,
      FwxxLoading: false,
      FwxmsftableLoading: false,
      showModal: false,
      show: true,
      addOrUpdateMain:{
        name:'',
      }
    };
  },
  methods: {
    //居家服务趋势/年
    getFunjgyfwdxbd(){
      this.AgeLoading = true
      getTwoRightList().then(res=>{
        this.AgeLoading = false
        this.dataMain.jgyfwdxbdData = res;
      })
    },
    //养老院坐标信息
    getFunNl(){
      this.tableJgLoading = true
      getOneDtList().then(res=>{
        let list = res.oneYlXxVO;
        let listzb = res.oneYlZbVO;
        this.dataMain.orderManNlData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.orderManNlData = list;
        this.tableJgLoading = false
      })
    },
    //政府信息公示窗口
    getFunRs(){
      this.RstableLoading=true
      getOneRightOneListPage().then(res=>{
        this.RstableLoading=false
        this.dataMain.fwryRsData = res;
      })
    },
    //日间照料坐标信息
    getFunFwryJg(){
      this.rjzlTableLoading = true;
      getThreeLeftList().then(res=>{
        this.dataMain.rjzlData = res;
        this.rjzlTableLoading = false
      })
    },
    //区县老人、区县失能老人、区县居家服务人次、区县服务投诉排名
    getFunGd(){
      this.FwxmsftableLoading = true
      getThreeRightList().then(res=>{
        this.dataMain.fwxmsfData = res;
        this.FwxmsftableLoading = false
      })
    },
    //服务人次信息
    getFunFwxx(){
      this.FwxxLoading = true
      getTwoTopList().then(res=>{
        this.dataMain.qxfwjgData = res;
        this.FwxxLoading = false
      })
    },
    oldManSave(type,props) {
      let data =  props.row;
      if(type == '排名'){
        updateThreeRight(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunGd()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '城市规划养老床位缺口占比'){
        updateThreeLeft(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunFwryJg()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务人次信息'){
        updateTwoTop(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunFwxx()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务趋势/月'){
        updateTwoRight(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunjgyfwdxbd()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
    },
  },
  //todo
  //---------------------初始化加载 ----------------------
  mounted() {
    this.tableHeight = window.innerHeight * 0.5 - 90;
    window.onresize = () => {
      return (() => {
        this.tableHeight = window.innerHeight * 0.5 - 90;
      })();
    };

    this.getFunNl();
    this.getFunFwryJg();
    this.getFunGd();
    this.getFunFwxx();
    this.getFunjgyfwdxbd();
    this.getFunRs();
  },
  //---------------------初始化加载 END----------------------
};
</script>
<style>
  .ivu-icon{
    left: 25px !important;
  }
</style>